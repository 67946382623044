import * as React from 'react'
import { RouteObject, Outlet, Navigate } from 'react-router-dom'

import { UserDebugList } from './list/UserDebugList'

import { UserDebugLayout } from './layout/UserDebugLayout'

const UserDebugRoutes: RouteObject = {
  path: '/user/debug',
  id: 'debug',
  element: (
    <React.Suspense fallback={<div>Loading...</div>}>
      <UserDebugLayout>
        <Outlet />
      </UserDebugLayout>
    </React.Suspense>
  ),
  children: [
    {
      index: true,
      element: <UserDebugList />
    },
    {
      path: '*',
      element: <Navigate to='.' />
    }
  ]
}

export { UserDebugRoutes }
