import { graphql } from '../generated/gql'

export const Permissions = graphql(/* GraphQL */ `
  query actorPermissionsForEmployees($employeeIds: [ID!]!) {
    actorPermissionsForEmployees(employeeIds: $employeeIds) {
      employeeId
      permissions
    }
  }
`)

export const AccessibleCustomersQuery = graphql(/* GraphQL */ `
  query AccessibleCustomers($input: AccessibleCustomersInput!) {
    accessibleCustomers(input: $input) {
      customers {
        companyCode
        shardName
        name
      }
      nextCursor
    }
  }
`)
