import * as React from 'react'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { useQuery } from '@apollo/client'
import { Events } from './EventsModal'
import { EmailVerificationEvents } from '@local/api/documents'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { SubmitButton, TextInputField } from '@toasttab/buffet-pui-forms'
import { GenericForm } from '../components/generic-form'
import { Form, Formik } from 'formik'

export const EmailVerificationEventsModal = (props: {
  onClose: () => void
  toastIdentityGuid: string | undefined | null
}) => {
  const { onClose, toastIdentityGuid } = props
  const [searchToastIdentityGuid, setSearchToastIdentityGuid] =
    React.useState(toastIdentityGuid)

  const { data, loading, error, client } = useQuery(
    EmailVerificationEvents,
    searchToastIdentityGuid
      ? {
          variables: { toastIdentityGuid: searchToastIdentityGuid }
        }
      : { skip: true }
  )

  const updatedData = data?.emailVerification?.events.map((event: any) => {
    return {
      ...event,
      timestamp: format(
        new Date(event['eventTime']),
        Formats.dateTime.medium_with_seconds
      )
    }
  })

  React.useEffect(() => {
    return () => {
      client.cache.evict({ fieldName: 'emailVerification' })
    }
  }, [])

  const ToastIdentityGuidForm = () => {
    return (
      <Formik
        onSubmit={(values) => {
          setSearchToastIdentityGuid(values.toastIdentityGuid)
        }}
        initialValues={{
          toastIdentityGuid: searchToastIdentityGuid
        }}
      >
        {(formik) => {
          return (
            <form
              id='toast-identity-guid-form'
              onSubmit={formik.handleSubmit}
              className='space-y-4'
            >
              <div className='flex space-x-4 ml-4 mr-4'>
                <TextInputField
                  containerClassName='flex-grow'
                  name='toastIdentityGuid'
                  label='Toast identity guid'
                  helperText='Enter the toast identity guid'
                />
              </div>
              <div className='flex justify-end space-x-4 mr-4'>
                <SubmitButton form='toast-identity-guid-form'>
                  Submit
                </SubmitButton>
              </div>
            </form>
          )
        }}
      </Formik>
    )
  }

  return (
    <Modal
      size='xl'
      isOpen={true}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
      position='pin-right'
    >
      <ModalHeader>Email Verification Events</ModalHeader>

      {!toastIdentityGuid && <ToastIdentityGuidForm />}

      <ModalBody>
        <Events
          eventType='Email Verification'
          events={updatedData}
          loading={loading}
          error={error?.message}
        />
      </ModalBody>
    </Modal>
  )
}
